<script setup>
/**
 * Render a sanitized html text message
 */
import { defineProps, computed } from "vue";
import DOMPurify from "dompurify";

const props = defineProps({
  message: Object,
});

/**
 * Html sanitized message
 */
const sanitizedMessage = computed(() => {
  return DOMPurify.sanitize(props.message.text);
});
</script>

<template>
  <div
    class="sy-text-message"
    v-html="sanitizedMessage"
  />
</template>

<style lang="scss">
.sy-text-message {
  white-space: pre-wrap;
  padding: 0.25rem;
}
</style>
